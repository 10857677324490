import React from 'react';
import EmptyContent from '../empty-content/empty-content';
import NotificationItem from './NotificationItem';
import { Button } from '../atom/button';
import { routePaths } from '@/routes/routePaths';
import { User } from 'next-auth';
import useGetUserNotifications from '@/hooks/react-query/notifications/queries/useGetUserNotifications';
import NotificationLoader from './NotificationLoader';
import { useToast } from '@/hooks/use-toast';
import useMarkAllAsReadMutation from '@/hooks/react-query/notifications/mutations/useMarkAllAsReadMutation';
import Link from 'next/link';
import { useQueryClient } from '@tanstack/react-query';

const MinifiedNotificationList = ({ user }: { user?: User }) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const markAllAsReadMutation = useMarkAllAsReadMutation();

  const {
    data: allNotifications,
    isLoading,
    hasNextPage,
  } = useGetUserNotifications({
    UserId: user?.userId,
    PageSize: 5,
  });

  const handleMarkAllAsRead = () => {
    const params = {
      UserId: user?.userId || 0,
    };

    markAllAsReadMutation.mutate(params, {
      onSuccess: (v) => {
        toast({
          variant: 'success',
          description: `Request successful`,
        });
        queryClient.invalidateQueries();
      },
      onError: (msg) => {
        toast({
          variant: 'destructive',
          description: 'Action failed.',
        });
      },
    });
  };

  return (
    <div className="w-full h-[calc(100vh-80px)] bg-white flex flex-col">
      <div className="bg-white h-11 min-h-11 px-2 md:px-4 flex justify-between items-center md:gap-2 rounded-lg mb-2">
        <h2 className="text-base font-normal text-[#601E8A]">NOTIFICATIONS</h2>

        {allNotifications && (
          <Button
            variant="ghost"
            className="text-orange-brand hover:text-orange-brand2 px-0 mx-0"
            onClick={handleMarkAllAsRead}
          >
            Mark All As Read
          </Button>
        )}
      </div>

      <div className="w-full h-[calc(100vh-80px-44px-48px)] flex-grow overflow-auto">
        {isLoading ? (
          <NotificationLoader />
        ) : (
          <div className="w-full flex flex-col mb-2">
            {allNotifications && allNotifications?.length > 0 ? (
              allNotifications?.map((notification: any) => (
                <NotificationItem
                  key={notification}
                  hasBeenRead={notification.isRead}
                  senderAvatarUrl={notification.sender?.avatarUrl}
                  senderFullName={notification.sender?.fullName}
                  title={notification.title}
                  body={notification.body}
                  extraData={notification.data?.extraData}
                  notificationTypeId={notification.data?.notificationTypeId}
                  notificationIdentity={notification.id}
                />
              ))
            ) : (
              <div className="w-full h-40 flex items-center justify-center">
                <EmptyContent title="You have no notfications." />
              </div>
            )}
          </div>
        )}
      </div>

      {hasNextPage && (
        <div className="h-12 flex items-center justify-center">
          <Link
            href={routePaths.notifications}
            className="text-orange-brand hover:text-orange-brand2 px-4 mx-0 "
          >
            View All
          </Link>
        </div>
      )}
    </div>
  );
};

export default MinifiedNotificationList;
