import { Skeleton } from '../atom/skeleton';

const NotificationLoaderItem = () => {
  return (
    <div className={'w-full px-4 md:px-6 py-6 flex gap-2'}>
      <Skeleton className="h-8 w-8 rounded-full" />

      <div className="w-full">
        <div className="w-full flex items-center justify-between mb-0.5 gap-2">
          <Skeleton className="h-6 w-40" />
          <Skeleton className="h-4 w-8"></Skeleton>
        </div>

        <Skeleton className="h-3 w-32" />
      </div>
    </div>
  );
};

const NotificationLoader = () => {
  return (
    <div className="flex flex-col bg-white">
      <NotificationLoaderItem />
      <NotificationLoaderItem />
      <NotificationLoaderItem />
    </div>
  );
};

export default NotificationLoader;
